import Button from '../components/Button'
import * as Outline from '@heroicons/react/outline'
import {Players, Videos} from '@eyecons/storybook'
import {useEffect, useState} from 'react'

export default function Player({video, autoplay = false, page, setPage, channel}) {
    const [share, setShare] = useState(false)

    useEffect(() => {
        if (autoplay && page === 'Player') {
            jwplayer().play()
        }
    }, [page])

    return <div className={'video-clip'}>
        <div className={'video-wrapper'}>
            <Players.Player id={'player'} video={video} playable autoplay={autoplay} ads/>

            <div className={'player-buttons'}>
                <Button className={'player-button'} onClick={() => setShare(!share)}>
                    <Outline.ShareIcon/>
                    <span>Delen</span>
                </Button>
                <Button
                    className={'player-button'}
                    onClick={() => {
                        setPage('Videos')
                        jwplayer().pause()
                    }}
                >
                    <Outline.ViewGridIcon/>
                    <span>Videos</span>
                </Button>

                {
                    share && <Videos.Share
                        type={'player'}
                        video={video}
                        onClose={() => setShare(false)}
                        site_url={process.env.REACT_APP_SITE_URL}
                    />
                }
            </div>
        </div>
        {
            channel.data.videowall_sponsor &&
            <div className={'video-sponsor'}>
                <img className={'video-sponsor-img'} alt={'sponsor'} src={`${channel.data.videowall_sponsor}`}/>
            </div>
        }
    </div>
}
