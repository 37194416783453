import * as Components from '../components'
import {useState, useEffect} from 'react'
import {Waypoint} from 'react-waypoint'
import {Icons} from '@eyecons/storybook'
import {Videos as StorybookVideos} from '@eyecons/storybook'

const paginate = 24

export default function Videos({currentVideo, setCurrentVideo, setPage, channel, playlist}) {
    const [videos, setVideos] = useState({
        loading: false,
        data: [],
        meta: {},
    })

    function getVideos(page = 1) {
        setVideos({...videos, loading: true})

        let url = `${process.env.REACT_APP_API_URL}/public/videos?paginate=${paginate}&page=${page}&channel_id=${channel.id}&is_free=true&tags=true&resource=VideoFull&type[]=vod&type[]=event&sort[]=starred desc&sort[]=created_at desc`

        if (playlist) {
            url += `&playlist=${playlist}`
        }

        return fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Something went wrong')
                }
            })
            .then(response => {
                response.data = videos.data.concat(response.data)
                setVideos({...response, loading: false})
                return response
            })
            .catch(() => setPage('NotFound'))
    }

    useEffect(() => {
        if (channel.id) {
            getVideos().then(response => {
                if (response.data.length) {
                    setCurrentVideo({
                        autoplay: false,
                        video: response.data[0],
                    })
                }
            })
        }
        // eslint-disable-next-line
    }, [])

    if (!videos.loading && !videos.data.length) {
        return <div className={'videos-empty'}>
            <p>Geen video's gevonden.</p>
        </div>
    }

    return <>
        <StorybookVideos.Cards className={'videos-wrapper'}>
            {
                videos.data.map((video, index) => {
                    return <StorybookVideos.Card
                        className={'white'}
                        video={video}
                        key={index}
                        onClick={() => {
                            setCurrentVideo({
                                autoplay: true,
                                video,
                            })
                            setPage('Player')
                            jwplayer().pause()
                        }}
                    />
                })
            }
        </StorybookVideos.Cards>

        {
            videos.meta.current_page !== videos.meta.last_page &&
            <Waypoint onEnter={() => getVideos(videos.meta.current_page + 1)}/>
        }
        {
            videos.loading &&
            <div className={`loader-wrapper ${!videos.meta.current_page ? 'mid-loader' : ''}`}>
                <Icons.Icon name={'loader'}/>
            </div>
        }
        {
            currentVideo.thumbnail && <div className={'current-video'}>
                <img alt={'video'} src={currentVideo.thumbnail}/>
            </div>
        }

        {Boolean(videos.data.length) && <Components.Close onClick={() => setPage('Player')}/>}
    </>
}

