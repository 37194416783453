import React, {useEffect, useState} from 'react'
import * as Pages from './pages'
import {Pages as StorybookPages} from '@eyecons/storybook'
import * as Helpers from './helpers'
import * as Router from 'react-router-dom'

function Main() {
    let channel_name = process.env.REACT_APP_CHANNEL || Router.useParams().channel
    let query = Helpers.useQuery()
    const [currentVideo, setCurrentVideo] = useState({
        video: {}
    })

    const [channel, setChannel] = useState({
        loading: true,
        data: [],
        meta: {},
    })

    const [page, setPage] = useState('Videos')

    function getChannel() {
        setChannel({...channel, loading: true})
        let url = `${process.env.REACT_APP_API_URL}/public/channels?name=${channel_name}&details=true`

        fetch(url)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Something went wrong')
                }
            })
            .then(json => {
                if (!json.data.length) {
                    return setPage('NotFound')
                }
                setChannel({data: json.data[0], loading: false})
            })
            .catch(() => setPage('NotFound'))
    }

    useEffect(() => {
        getChannel()
    }, [])

    if (channel.loading) {
        return <></>
    }

    if (!channel.data.id) {
        return <div className={'page-notfound'}>
            <StorybookPages.NotFound/>
        </div>
    }

    return <>
        <div className={Helpers.classNames('page', page === 'Videos' ? 'page-active' : '')}>
            {
                <Pages.Videos
                    playlist={query.get('playlist')}
                    channel={channel.data}
                    currentVideo={currentVideo}
                    setCurrentVideo={setCurrentVideo}
                    setPage={setPage}
                />
            }
        </div>
        <div className={Helpers.classNames('page', page === 'Player' ? 'page-active' : '')}>
            {
                currentVideo.video.id && <Pages.Player
                    video={currentVideo.video}
                    autoplay={currentVideo.autoplay}
                    page={page}
                    setPage={setPage}
                    channel={channel}
                />
            }
        </div>
    </>
}

function App() {
    return <Router.BrowserRouter>
        <Router.Switch>
            <Router.Route path={'/:channel'} component={Main}/>
            <Router.Route path={'//:channel'} component={Main}/>
            <Router.Route path={'/'} component={Main}/>
        </Router.Switch>
    </Router.BrowserRouter>
}

export default App

