import * as Router from 'react-router-dom'
import React from 'react'

export function classNames (...classes) {
    return classes.filter(Boolean).join(' ')
}


export function useQuery() {
    const { search } = Router.useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
